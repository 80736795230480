<template>
    <div class="technical-analysis">
      <div class="indicator-group">
        <h4>趋势指标</h4>
        <div v-for="(trend, index) in data.trends" 
             :key="index" 
             class="trend-item">
          <el-tag :type="trend.type">{{ trend.label }}</el-tag>
          <p>{{ trend.description }}</p>
        </div>
      </div>
      <div class="indicator-group">
        <h4>支撑压力位</h4>
        <div class="support-resistance">
          <div class="level">
            <span>压力位:</span>
            <span class="resistance">{{ data.resistance }}</span>
          </div>
          <div class="level">
            <span>支撑位:</span>
            <span class="support">{{ data.support }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TechnicalAnalysis',
    props: {
      data: {
        type: Object,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .indicator-group {
    margin-bottom: 20px;
  }
  
  .trend-item {
    margin-bottom: 15px;
  }
  
  .trend-item p {
    margin: 5px 0;
    color: #606266;
  }
  
  .support-resistance {
    margin-top: 10px;
  }
  
  .level {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .support { color: #67c23a; }
  .resistance { color: #f56c6c; }
  </style>