// api.js - 用于集中管理 API 请求
import axios from 'axios';

const api = axios.create({
//   baseURL: process.env.VUE_APP_API_BASE_URL,
    baseURL:"http://stock.iamhere.top/api"
    // baseURL:"http://127.0.0.1:8000/api"
});

export default api;
