// store.js
import { createStore } from 'vuex';
const storedUserInfo = localStorage.getItem('userInfo');
const storedToken = localStorage.getItem('token');
export default createStore({
  state: {
    isLoggedIn: !!storedToken, // 如果有 token 则为 true
    userInfo: storedUserInfo ? JSON.parse(storedUserInfo) : null, // 存储用户信息
    token: storedToken // 存储 token
  },
  mutations: {
    SET_LOGIN_STATE(state, payload) {
      state.isLoggedIn = payload.isLoggedIn;
      state.userInfo = payload.userInfo;
      state.token = payload.token;
    },
    LOGOUT(state) {
      state.isLoggedIn = false;
      state.userInfo = null;
      state.token = null;
      localStorage.setItem('isLoggedIn', false);
      localStorage.setItem('token', '');
      localStorage.setItem('userInfo', '');
    }
  },
  actions: {
    loginStore({ commit }, userData) {
      commit('SET_LOGIN_STATE', {
        isLoggedIn: true,
        userInfo: userData.userInfo,
        token: userData.token
      });
      console.log(userData)
    },
    logoutStore({ commit }) {
      commit('LOGOUT');
    }
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    userInfo: state => state.userInfo,
    token: state => state.token
  }
});