export function getLastWeekDateRange() {
    const today = new Date();
    
    // 获取当前日期
    const endDate = formatDate(today);
    
    // 获取一周前的日期
    const startDate = formatDate(new Date(today.setDate(today.getDate() - 7)));
  
    return { startDate, endDate };
  }
  
  // 格式化日期为 YYYY-MM-DD
function formatDate(date) {
const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
const day = String(date.getDate()).padStart(2, '0');

return `${year}-${month}-${day}`;
}