<template>
    <div class="stock-analysis-section">
      <!-- 搜索栏 -->
      <el-card class="search-card">
        <div class="search-input-group">
          <el-input
            v-model="stockCode"
            placeholder="请输入股票代码"
            @keyup.enter="handleAnalyze"
          >
            <template #prepend>股票代码</template>
          </el-input>
          <el-button 
            type="primary" 
            @click="handleAnalyze"
            :loading="loading"
          >
            分析
          </el-button>
        </div>
      </el-card>
  
      <!-- 分析结果展示 -->
      <div v-if="analysisResult" class="analysis-content">
        <el-row :gutter="20">
          <!-- 技术指标分析卡片 -->
          <el-col :span="12">
            <el-card class="analysis-card">
              <template #header>
                <div class="card-header">
                  <span>技术指标分析</span>
                  <el-tooltip content="基于最新交易日K线数据的技术指标分析">
                    <el-icon><DataAnalysis /></el-icon>
                  </el-tooltip>
                </div>
              </template>
              <TechnicalAnalysis :data="analysisResult.data.technical" />
            </el-card>
          </el-col>
  
          <!-- 综合建议卡片 -->
          <el-col :span="12">
            <el-card class="analysis-card">
              <template #header>
                <div class="card-header">
                  <span>综合分析建议</span>
                  <el-tooltip content="基于技术指标的综合建议">
                    <el-icon><TrendCharts /></el-icon>
                  </el-tooltip>
                </div>
              </template>
              <RecommendationAnalysis :data="analysisResult.data.recommendation" />
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { ElMessage } from 'element-plus'
  import TechnicalAnalysis from './analysis/TechnicalAnalysis.vue'
  import RecommendationAnalysis from './analysis/RecommendationAnalysis.vue'
  import { analyzeStock } from '@/utils/stockAnalysis'
  
  export default {
    name: 'StockAnalysis',
    
    components: {
      TechnicalAnalysis,
      RecommendationAnalysis
    },
  
    setup() {
      const stockCode = ref('')
      const loading = ref(false)
      const analysisResult = ref(null)
  
      const handleAnalyze = async () => {
        if (!stockCode.value) {
          ElMessage.warning('请输入股票代码')
          return
        }
  
        loading.value = true
        try {
          const result = await analyzeStock({
            ts_code: stockCode.value
          })
          // 确保结果包含必要的字段
            // if (!result || !result.technical || !result.recommendation) {
            // throw new Error('数据格式错误')
            // }
          analysisResult.value = result
          ElMessage.success('分析完成')
        } catch (error) {
            console.error('Analysis error:', error)  // 添加错误日志
            ElMessage.error(error.message || '分析失败')
            analysisResult.value = null
        } finally {
          loading.value = false
        }
      }
  
      return {
        stockCode,
        loading,
        analysisResult,
        handleAnalyze
      }
    }
  }
  </script>
  
  <style scoped>
  .stock-analysis-section {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  width: 100%;
  /* min-height: 200px; */
}

.search-card {
  margin-bottom: 20px;
}

.search-input-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-input-group .el-input {
  flex: 1;
}

.analysis-content {
  margin-top: 20px;
}

.analysis-card {
  height: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 2px 12px rgba(0,0,0,0.1);
}

.analysis-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

/* 响应式布局 */
@media screen and (max-width: 768px) {
  .stock-analysis-section {
    padding: 10px;
  }

  .el-row {
    margin: 0 !important;
  }
  
  .el-col {
    padding: 0 !important;
  }
  
  .analysis-card {
    margin-bottom: 20px;
  }
}

:deep(.el-card__body) {
  padding: 20px;
}

:deep(.el-card__header) {
  padding: 15px 20px;
  border-bottom: 1px solid #ebeef5;
}
  </style>