import request from '@/utils/api'

export const analyzeStock = async (params) => {
    try {
      const response = await request({
        url: '/stock_analysis',
        method: 'get',
        params
      })
      if (response.error) {
        throw new Error(response.error)
      }
      return response.data  // 返回 data 字段中的数据
    } catch (error) {
      throw new Error(error.response?.data?.error || error.message || '分析失败')
    }
  }

export function getStockBaseData(params) {
  return request({
    url: '/api/stock/base-data',
    method: 'get',
    params
  })
}

export function getStockTechnicalData(params) {
  return request({
    url: '/api/stock/technical-data',
    method: 'get',
    params
  })
}

export function getStockMarketData(params) {
  return request({
    url: '/api/stock/market-data',
    method: 'get',
    params
  })
}