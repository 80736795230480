<template>
  <div class="user-auth-container">
    <el-form :model="form" ref="form" class="user-auth-form">
      <el-form-item label="手机号" prop="phone" :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' }]">
        <el-input v-model="form.phone" placeholder="请输入手机号" />
      </el-form-item>
      <el-form-item label="密码" prop="password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]">
        <el-input type="password" v-model="form.password" placeholder="请输入密码" />
      </el-form-item>
      <div class="button-group">
        <el-button type="primary" class="auth-button" @click="register">注册</el-button>
        <el-button type="primary" class="auth-button" @click="login">登录</el-button>
        <!-- <el-button type="danger" class="auth-button" v-if="isLoggedIn" @click="logout">退出登录</el-button> -->
      </div>
    </el-form>
  </div>
</template>

<script>
import api from '@/utils/api'; // 确保你有一个api.js文件来处理API请求
import { mapActions } from 'vuex'; // 引入 Vuex 的 mapActions
export default {
  name: "UserAuth",
  data() {
    return {
      form: {
        phone: '',
        password: ''
      },
    };
  },
  methods: {
    ...mapActions(['loginStore']), // 映射 Vuex 的 login 操作
    async register() {
      try {
        const response = await api.post('/register', this.form);
        console.log(response)
        this.$message.success('注册成功！');
      } catch (error) {
        this.$message.error('注册失败：' + error.response.data.error);
      }
    },
    async login() {
      try {
        const response = await api.post('/login', this.form);
        let token = ""
        token = response.data.token; // 存储token
        const userInfo = response.data;
        console.log(userInfo)
        this.loginStore({ userInfo, token });

        this.isLoggedIn = true; // 更新登录状态
        // 调用 Vuex 的 login 操作

        // 存储到 localStorage
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('token', token);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));

        this.$message.success('登录成功！');
        this.$emit('login-success',true)
      } catch (error) {
        this.$message.error('登录失败：' + error.response.data.error);
      }
    },
    async logout() {
      this.$message.success('已退出登录');
    }
  },
  mounted() {
    // this.token = localStorage.getItem('token'); // 检查是否有token
    // this.isLoggedIn = !!this.token; // 更新登录状态
  }
};
</script>


<style scoped>
.user-auth-container {
  max-width: 400px; /* 设置最大宽度 */
  margin: 0 auto; /* 居中对齐 */
  padding: 30px; /* 内边距 */
  border: 1px solid #e0e0e0; /* 边框 */
  border-radius: 12px; /* 圆角 */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  background-color: #f9f9f9; /* 背景颜色 */
}

.user-auth-form {
  display: flex;
  flex-direction: column; /* 垂直排列 */
}

.user-auth-form .el-form-item {
  margin-bottom: 20px; /* 表单项之间的间距 */
}

.button-group {
  display: flex;
  justify-content: space-between; /* 按钮之间的间距 */
}

.auth-button {
  flex: 1; /* 按钮占据相同的宽度 */
  margin-right: 10px; /* 右边距 */
  border-radius: 8px; /* 按钮圆角 */
  padding: 10px; /* 按钮内边距 */
  font-size: 16px; /* 按钮字体大小 */
}

.auth-button:last-child {
  margin-right: 0; /* 最后一个按钮不需要右边距 */
}

.el-input {
  border-radius: 8px; /* 输入框圆角 */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* 输入框阴影 */
}
</style>