<template>
    <div ref="chart" style="height: 400px; width: 100%;"></div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    props: ['type', 'chartData'],
    data() {
      return {
        chartInstance: null,
      };
    },
    computed: {
      chartOptions() {
        return {
          title: { text: '' },
          tooltip: {},
          xAxis: { data: this.chartData ? this.chartData.dates : [] },
          yAxis: [{
            type: 'value'
            }],
          legend:{
            data:this.chartData?this.chartData.legend:[]
          },
          series: this.chartData ? this.chartData.series : [],
        };
      },
    },
    methods: {
      getTitle() {
        switch (this.type) {
          case 'stock_volume_top_stats':
            return '成交量占比统计';
          case 'stock_lowest_price_stats':
            return '最低价平均统计';
          case 'stock_price_range_volume_stats':
            return '价格区间成交量统计';
          default:
            return '';
        }
      },
      renderChart() {
        if (this.chartInstance) {
          this.chartInstance.setOption(this.chartOptions);
        }
      },
      initChart() {
        this.chartInstance = echarts.init(this.$refs.chart);
        this.renderChart();
      },
    },
    watch: {
      chartData: 'renderChart',
      type: 'renderChart',
    },
    mounted() {
      
      this.initChart();
      window.addEventListener('resize', this.chartInstance.resize);
    },
    beforeUnmount() {
      if (this.chartInstance) {
        this.chartInstance.dispose();
      }
      window.removeEventListener('resize', this.chartInstance.resize);
    },
  };
  </script>
  
  <style scoped>
  /* Optional: Add any additional styles for the chart container */
  </style>
  