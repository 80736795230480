<template>
  <div>
    <div class="head-title">
      <img src="" alt="">
      <h2>一垚壬机</h2>
    </div>
    <div class="control">
      <el-button v-if="!isLoggedIn" @click="showLogin">登录</el-button>
      <el-button v-if="isLoggedIn" @click="showUserInfo">个人信息</el-button>
      <el-button v-if="isLoggedIn" @click="logout">退出登录</el-button>
    </div>
    
    <el-dialog v-model="dialogInfoVisible">
      <template #title>个人信息</template>
      <div>
        <p>账号: {{ userInfo.phone }}</p>
        <p>订阅记录: {{ 0}}</p>
        <p>剩余权限次数: {{ userInfo.remaining_permissions }}</p>
      </div>
      <template #footer>
        <el-button @click="dialogInfoVisible = false">关闭</el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible" title="登录" @close="dialogVisible = false" style="max-width: 350px;">
      <UserAuth @login-success="handleLoginSuccess" />
    </el-dialog>

    <!-- 股票分析组件 -->
    <div class="hit-stock">
      <div class="stock-picks">
        <div class="picks-header">
          <div class="title">
            <i class="icon">📈</i>
            <span>股票分析</span>
          </div>
          <div class="subtitle">基于高胜率策略和均线对股票进行详细分析</div>
        </div>
        <div class="picks-container">
            <StockAnalysis />
        </div>
      </div>
      <div class="stock-picks">
        <div class="picks-header">
          <div class="title">
            <i class="icon">📈</i>
            <span>今日表现</span>
          </div>
          <div class="subtitle">基于成交量和涨跌幅智能筛选</div>
        </div>
        <div class="picks-container">
          <div v-for="(stock, index) in topPicks" :key="index" class="stock-card">
            <div class="card-header">
              <span class="stock-code">{{ stock.name }}</span>
              <span class="rank-tag">Top {{ index + 1 }}</span>
            </div>
            <div class="stock-info">
              <div class="info-item">
                <span class="label">成交量</span>
                <span class="value">{{ formatVolume(stock.value) }}</span>
              </div>
              <div class="info-item">
                <span class="label">涨跌幅</span>
                <span class="value" :class="{'up': stock.pct >= 0, 'down': stock.pct < 0}">
                  {{ stock.pct }}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HitChartDisplay :chartData="hitChartData" v-loading="loading_zoom"></HitChartDisplay>
    </div>
    <div class="static">
      <el-tabs
      v-model="activeTab"
      type="card"
      class="demo-tabs"
      @tab-change="handleTabClick"
    >
      <el-tab-pane
        :key="tabs[0].label"
        :label="tabs[0].label"
        :name="0"
      >
        
      </el-tab-pane>
      <el-tab-pane
        :key="tabs[1].label"
        :label="tabs[1].label"
        :name="1"
      >
      </el-tab-pane>
      <el-tab-pane
        :key="tabs[2].label"
        :label="tabs[2].label"
        :name="2"
      >
      </el-tab-pane>
    </el-tabs>
      <el-card :body-style="{ padding: '20px' }" class="chart-card">
        <el-date-picker
          v-model="date_time_range"
          type="daterange"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :shortcuts="shortcuts"
          :size="size"
        />
        <el-radio-group v-if="activeTab==1" v-model="lowest_price_type" style="margin-left:20px ;">
          <el-radio value="0" size="large">平均价格</el-radio>
          <el-radio value="1" size="large">平均量</el-radio>
        </el-radio-group>
        <el-radio-group v-if="activeTab==2" v-model="price_range_volume_type" style="margin-left:20px ;">
          <el-radio value="0" size="large">区间数量</el-radio>
          <el-radio value="1" size="large">区间成交量</el-radio>
        </el-radio-group>
      </el-card>
      <!-- Chart Display -->
      <el-card :body-style="{ padding: '20px' }" class="chart-card" v-loading="loading" >
        <ChartDisplay :type="tabs[activeTab].type" :chartData="chartData" />
      </el-card>

      <!-- Data List -->
      <!-- <el-card :body-style="{ padding: '20px' }" class="data-list-card" style="margin-top: 20px;" v-loading="loading" >
        <DataList :type="tabs[activeTab].type" :tableData="tableData" />
      </el-card> -->
    </div>
  </div>
</template>

<script>
import ChartDisplay from './components/ChartDisplay.vue'
import HitChartDisplay from './components/HitChartDisplay.vue'
// import DataList from './components/DataList.vue'
import api from '@/utils/api';
import {getLastWeekDateRange} from '@/utils/utils'
import StockAnalysis from './components/StockAnalysis.vue'
import UserAuth from './components/UserAuth.vue'; // 确保引入 Auth 组件
import { mapState, mapActions } from 'vuex'; // 引入 Vuex 的 mapState
export default {
  components: { ChartDisplay,HitChartDisplay,StockAnalysis,UserAuth },
  data() {
    return {
      dialogVisible: false,
      dialogInfoVisible:false,
      // userInfo: {
      //   phone: '',
      //   subscriptions: [],
      //   remainingPermissions: 0
      // },
      // isLoggedIn: false, // 新增状态来跟踪用户是否登录
      topPicks: [],
      activeTab: 0,
      loading:false,
      loading_zoom:true,
      tabs: [
        { label: '成交量占比统计', type: 'stock_volume_top_stats' },
        { label: '最低价平均统计', type: 'stock_lowest_price_stats' },
        { label: '价格区间成交量统计', type: 'stock_price_range_volume_stats' }
      ],
      chartData: null,
      tableData: null,
      hitChartData:null,
      price_range_volume_type:'0',
      lowest_price_type:'0',
      date_time_range:['2024-10-01','2024-11-01'],
      shortcuts : [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },]
    }
  },
  watch: {
    activeTab: 'fetchData',
    date_time_range: 'fetchData',
    price_range_volume_type: 'fetchData',
    lowest_price_type: 'fetchData',
  },
  computed: {
    ...mapState(['isLoggedIn', 'userInfo']), // 映射 Vuex 状态
  },
  methods: {
    ...mapActions(['loginStore', 'logoutStore']), // 映射 Vuex 操作
    async showLogin(){
      this.dialogVisible = true
    },
    showUserInfo() {
      console.log(this.userInfo)
      // 获取用户信息逻辑
      this.dialogInfoVisible = true;
    },
    // 登录成功
    handleLoginSuccess(){
      this.dialogVisible = false
    },
    async logout() {
      // 退出登录逻辑
      // this.isLoggedIn = false; // 更新状态为未登录
      this.logoutStore()
    },
    async fetchData() {
      this.loading = true
      const type = this.tabs[this.activeTab].type
      const response = await api.get('/analysis', {
        params: {
          type: type,
          start_date: this.date_time_range[0],
          end_date: this.date_time_range[1],
        },
      });
      console.log(this.date_time_range)
      let allData = {}
      if(this.activeTab == 0){
        allData = this.stock_volume_top_data(response.data.data)
        this.chartData = allData.chartData
        this.tableData = allData.dataList
        console.log(allData)
      }else if(this.activeTab == 1){
        allData = this.stock_lowest_price_data(response.data.data,this.lowest_price_type)
        this.chartData = allData.chartData
        this.tableData = allData.dataList
        console.log(allData)

      }else if(this.activeTab == 2){
        allData = this.stock_price_range_volume_data(response.data.data,this.price_range_volume_type)
        this.chartData = allData.chartData
        this.tableData = allData.dataList
        console.log(allData)
      }
      this.loading = false
    },
    //获取热度股票列表板块 默认成交量前30只
    async ferchHitData(){
      this.loading = true
      const response = await api.get('/hit_data', {
        params: {
          count: 30
        },
      });
      let allData = {}
      if(response.data){
        allData = this.stock_hit_data(response.data)
        this.hitChartData = allData
        console.log(allData)
      }
      this.loading = false
    },
    handleTabClick(){
      console.log(this.activeTab)
    },
    // 数据处理 成交量占比统计
    stock_volume_top_data(data){
      let dataList = []
      let chartData = {
        legend:[],
        series:[
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          }
        ],
        dates:[]
      }
      if(data){
        chartData.legend = Object.keys(data[0].data)
      }else{
        return {}
      }
      for(let i = 0;i<data.length;i++){
        chartData.dates.push(data[i].date)
        for(let j=0;j<10;j++){
          chartData.series[j].name = chartData.legend[j]
          chartData.series[j].data.push(data[i].data[chartData.legend[j]])
        }
        let list = {'date':data[i].date,...data[i].data}
        dataList.push(list)
      }
      console.log(dataList)
      return {chartData,dataList}
    },
    // 数据处理 最低价平均统计
    stock_lowest_price_data(data,type=1){
      let newdata = []
      console.log('ssssss',data)
      if(type == '0'){
        newdata = {
          data: data.map(item => ({
            date: item.date,
            data: Object.fromEntries(
              Object.entries(item.data).filter(([key]) => !key.includes('avg_volume'))
            )
          }))
        };
      }else{
        newdata = {
          data: data.map(item => ({
            date: item.date,
            data: Object.fromEntries(
              Object.entries(item.data).filter(([key]) => !key.includes('avg_price'))
            )
          }))
        };
      }
      newdata = newdata.data
      console.log('aaaaa',newdata)
      let dataList = []
      let chartData = {
        legend:[],
        series:[
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          }
        ],
        dates:[]
      }
      if(data){
        chartData.legend = Object.keys(newdata[0].data)
      }else{
        return {}
      }
      for(let i = 0;i<newdata.length;i++){
        chartData.dates.push(newdata[i].date)
        for(let j=0;j<10;j++){
          chartData.series[j].name = chartData.legend[j]
          chartData.series[j].data.push(newdata[i].data[chartData.legend[j]])
        }
        let list = {'date':newdata[i].date,...newdata[i].data}
        
        dataList.push(list)
      }
      
      console.log(dataList)
      return {chartData,dataList}
    },
    // 数据处理 价格区间成交量统计
    stock_price_range_volume_data(data,type=0){
      if(type == '0'){
        for(let k=0;k<data.length;k++){
          data[k].data = data[k].data.count_by_range
        }
      }else{
        for(let k=0;k<data.length;k++){
          data[k].data = data[k].data.volume_ratio_by_range
        }
      }
      let dataList = []
      let chartData = {
        legend:[],
        series:[
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          },
          {
            type:'line',
            name:'',
            data:[]
          }
        ],
        dates:[]
      }
      if(data){
        chartData.legend = Object.keys(data[0].data)
      }else{
        return {}
      }
      for(let i = 0;i<data.length;i++){
        chartData.dates.push(data[i].date)
        for(let j=0;j<10;j++){
          chartData.series[j].name = chartData.legend[j]
          chartData.series[j].data.push(data[i].data[chartData.legend[j]])
        }
        let list = {'date':data[i].date,...data[i].data}
        dataList.push(list)
      }
      console.log(dataList)
      return {chartData,dataList}
    },
    // 数据处理 价格区间成交量统计
    stock_hit_data(data) {
      let hit_data = []
      data = data.data
      
      // 辅助函数：计算颜色
      const getColor = (pct) => {
        if (pct >= 0) {
          // 上涨：从浅红到深红
          if (pct >= 7) {
            return '#990000';      // 深红色 - 涨幅 >= 7%
          } else if (pct >= 5) {
            return '#cc0000';      // 红色 - 涨幅 5-7%
          } else if (pct >= 3) {
            return '#ff0000';      // 鲜红色 - 涨幅 3-5%
          } else if (pct >= 1) {
            return '#ff3333';      // 亮红色 - 涨幅 1-3%
          } else {
            return '#ff6666';      // 浅红色 - 涨幅 0-1%
          }
        } else {
          // 下跌：从浅绿到深绿
          if (pct <= -7) {
            return '#006600';      // 深绿色 - 跌幅 >= 7%
          } else if (pct <= -5) {
            return '#008800';      // 绿色 - 跌幅 5-7%
          } else if (pct <= -3) {
            return '#00aa00';      // 鲜绿色 - 跌幅 3-5%
          } else if (pct <= -1) {
            return '#00cc00';      // 亮绿色 - 跌幅 1-3%
          } else {
            return '#00ee00';      // 浅绿色 - 跌幅 0-1%
          }
        }
      }
      for(let k=0; k<data.length; k++){
        const pct = Number(data[k].pct_chg);

        hit_data.push({
          name: data[k].ts_code,
          value: data[k].vol,
          pct: data[k].pct_chg,
          itemStyle: {
            color: getColor(pct)
          }
        })
      }
      // 选股逻辑
      this.selectTopStocks(hit_data)
      return {
        legend: [],
        title: { text:'cd'},
        series: [{
          name:'成交量排列图',
          type: 'treemap',
          data: hit_data,
          silent: true,  // 禁用所有鼠标事件
          nodeClick: false,  // 禁用点击事件
          // breadcrumb: {
          //   show: false
          // },
          roam: false,
          // zoom: 1,
          // zoomLock: true,
          label: {
            show: true,
            formatter: function(params) {
              return `${params.name}\n${params.data.pct}%`  // 显示股票代码和涨跌幅
            }
          },
          toolbox: {
            show: false  // 隐藏工具栏
          },
        }],
        visualDimension: 1,
        visualMin: -5,
        visualMax: 5,
        color: ['#d73027', '#fee08b', '#1a9850']
      }
    },
     // 选股方法
     selectTopStocks(stocks) {
      // 选股策略：
      // 1. 成交量较大（排名前50%）
      // 2. 涨跌幅适中（1% ~ 5%之间）
      // 3. 避免已经涨停的股票
      
      let candidates = stocks.filter(stock => {
        const pct = Number(stock.pct)
        return pct > 1 && pct < 5  // 涨幅在1-5%之间
      })

      // 按成交量排序
      candidates.sort((a, b) => Number(b.value) - Number(a.value))
      
      // 取前50%的股票
      candidates = candidates.slice(0, Math.floor(candidates.length / 2))
      
      // 随机选择3只股票
      this.topPicks = this.shuffleArray(candidates).slice(0, 4)
    },

    // Fisher-Yates 洗牌算法
    shuffleArray(array) {
      let currentIndex = array.length
      let temporaryValue, randomIndex

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    },
    // 格式化成交量
    formatVolume(vol) {
      const num = Number(vol)
      if (num >= 100000000) {
        return (num / 100000000).toFixed(2) + '亿'
      } else if (num >= 10000) {
        return (num / 10000).toFixed(2) + '万'
      }
      return num.toFixed(2)
    }
  },
  mounted() {
    let date_time = getLastWeekDateRange()
    this.date_time_range = [date_time.startDate,date_time.endDate]
    this.fetchData()
    this.ferchHitData()
  }
}
</script>

<style scoped>
.head-title{
  text-align: center;
    font-family: fangsong;
    font-size: 21px;
}
.control{
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}
.stock-picks {
  margin: 20px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.08);
  cursor: pointer;
}

.picks-header {
  margin-bottom: 20px;
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.icon {
  font-size: 24px;
}

.subtitle {
  margin-top: 4px;
  font-size: 14px;
  color: #666;
}

.picks-container {
  display: flex;
  gap: 24px;
}

.stock-card {
  flex: 1;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.stock-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.stock-code {
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
}

.rank-tag {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background: #1a73e8;
  border-radius: 4px;
}

.stock-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.info-item:last-child {
  border-bottom: none;
}

.label {
  font-size: 14px;
  color: #666;
}

.value {
  font-size: 16px;
  font-weight: 500;
  color: #2c3e50;
}

.up {
  color: #ff4d4f;
  font-weight: 600;
}

.down {
  color: #52c41a;
  font-weight: 600;
}

@media (max-width: 768px) {
  .picks-container {
    flex-direction: column;
  }
  
  .stock-card {
    margin-bottom: 16px;
  }
}
.tabs {
  margin-bottom: 20px;
}

.chart-card,
.data-list-card {
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.el-row {
  margin-bottom: 10px;
}
.hit-stock{
    padding-bottom: 20px;
}
</style>
