<template>
    <div ref="hitChart" style="height: 400px; width: 100%;"></div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    props: ['chartData'],
    data() {
      return {
        chartInstance: null,
      };
    },
    computed: {
      chartOptions() {
        return {
          title: { text:'cd',show: false },
          tooltip: {
              formatter: function (info) {
                  var pct = info.data.pct;
                  return `${info.data.name}: ${pct}%`;
              }
          },
          xAxis: { data: this.chartData ? this.chartData.dates : [] },
          yAxis: [{
            type: 'value'
            }],
          legend:{
            data:this.chartData?this.chartData.legend:[]
          },
          series: this.chartData ? this.chartData.series : [],
        };
      },
    },
    methods: {
      renderChart() {
        if (this.chartInstance) {
          this.chartInstance.setOption(this.chartOptions);
        }
      },
      initChart() {
        this.chartInstance = echarts.init(this.$refs.hitChart);
        this.renderChart();
      },
    },
    watch: {
      chartData: 'renderChart',
      type: 'renderChart',
    },
    mounted() {
      
      this.initChart();
      // 添加鼠标滚轮事件监听器
      this.$refs.hitChart.addEventListener('mousewheel', this.preventDefault, { passive: false })
      this.$refs.hitChart.addEventListener('DOMMouseScroll', this.preventDefault, { passive: false })
    
      window.addEventListener('resize', this.chartInstance.resize);
    },
    beforeUnmount() {
      // 移除事件监听器
      this.$refs.hitChart.removeEventListener('mousewheel', this.preventDefault)
      this.$refs.hitChart.removeEventListener('DOMMouseScroll', this.preventDefault)
      if (this.chartInstance) {
        this.chartInstance.dispose();
      }
      window.removeEventListener('resize', this.chartInstance.resize);
    },
  };
  </script>
  
  <style scoped>
  /* Optional: Add any additional styles for the chart container */
  </style>
  