<template>
    <div class="recommendation-analysis">
      <!-- 综合评级 -->
      <div class="rating-section">
      <h3>综合评级</h3>
      <el-rate
        :model-value="data.rating" 
        :max="5"
        disabled
        show-score
        text-color="#ff9900"
        score-template="{value}分"
      />
    </div>
  
      <!-- 投资建议 -->
      <div class="advice-section">
        <el-alert
          :title="data.recommendation"
          :type="getRecommendationType"
          :description="data.reason"
          show-icon
        />
      </div>
  
      <!-- 关键指标总结 -->
      <div class="key-metrics">
        <el-divider>关键指标</el-divider>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="目标价格">
            {{ data.targetPrice }}
          </el-descriptions-item>
          <el-descriptions-item label="潜在收益">
            <span :class="getProfitClass">{{ data.potentialProfit }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="风险等级">
            <el-tag :type="getRiskType">{{ data.riskLevel }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="建议持仓时间">
            {{ data.holdingPeriod }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
  
      <!-- 投资要点 -->
      <div class="key-points">
        <el-divider>投资要点</el-divider>
        <el-timeline>
          <el-timeline-item
            v-for="(point, index) in data.keyPoints"
            :key="index"
            :type="getTimelineItemType(index)"
          >
            {{ point }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RecommendationAnalysis',
    
    props: {
      data: {
        type: Object,
        required: true,
        default: () => ({
          rating: 3,
          recommendation: '持有',
          reason: '综合各项指标，建议持有观望',
          targetPrice: '暂无',
          potentialProfit: '0%',
          riskLevel: '中等',
          holdingPeriod: '3-6个月',
          keyPoints: ['暂无投资要点']
        })
      }
    },
  
    computed: {
      getRecommendationType() {
        const rec = this.data.recommendation
        if (rec.includes('买入')) return 'success'
        if (rec.includes('卖出')) return 'danger'
        return 'warning'
      },
  
      getProfitClass() {
        const profit = this.data.potentialProfit
        return {
          'profit-positive': profit.startsWith('+'),
          'profit-negative': profit.startsWith('-')
        }
      },
  
      getRiskType() {
        const risk = this.data.riskLevel
        if (risk === '低') return 'success'
        if (risk === '中等') return 'warning'
        return 'danger'
      }
    },
  
    methods: {
      getTimelineItemType(index) {
        const types = ['primary', 'success', 'warning', 'danger']
        return types[index % types.length]
      }
    }
  }
  </script>
  
  <style scoped>
  .recommendation-analysis {
    padding: 20px;
  }
  
  .rating-section {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .advice-section {
    margin-bottom: 20px;
  }
  
  .key-metrics {
    margin: 20px 0;
  }
  
  .profit-positive {
    color: #67C23A;
    font-weight: bold;
  }
  
  .profit-negative {
    color: #F56C6C;
    font-weight: bold;
  }
  
  .key-points {
    margin-top: 20px;
  }
  
  h3 {
    color: #303133;
    margin-bottom: 15px;
  }
  </style>